!(function (window, document, sso, $) {

    'use strict';

    var gaReady = sso.init.production && sso.init.analyticsId && !sso.init.gaPrivacyCookie;

	$(document).ready(function () {
		$('[data-share=facebook]').on('click', function (evt) {
		    evt.preventDefault();
            if (gaReady) {
                ga('send', 'social', 'facebook', 'share-button', evt.delegateTarget.dataset.href);
            }
			var url = evt.delegateTarget.getAttribute('href');
            window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,location=0,status=no,width=600,height=400');
		});
		$('[data-share=twitter]').on('click', function (evt) {
		    evt.preventDefault();
			if (gaReady) {
                ga('send', 'social', 'twitter', 'share-button', evt.delegateTarget.dataset.href);
            }
			var url = evt.delegateTarget.getAttribute('href');
			window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
		});
		$('[data-share=pinterest]').on('click', function (evt) {
			if (gaReady) {
                ga('send', 'social', 'pinterest', 'share-button', evt.delegateTarget.dataset.href);
            }
		});
		$('[data-share=whatsapp]').on('click', function (evt) {
			if (gaReady) {
                ga('send', 'social', 'whatsapp', 'share-button', evt.delegateTarget.dataset.href);
            }
		});
	});

})(window, document, window.sso, jQuery);