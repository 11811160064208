!(function (window, document, sso, $) {

    'use strict';

    var utils = {};

    utils.getFacebookLikeCookieName = function () {
    	return 'like-'+ sso.init.verticalFanpageId;
	};

    utils.setFacebookLikeCookie = function (value) {
    	var cookieName = utils.getFacebookLikeCookieName();
    	document.cookie = cookieName +'='+ value +'; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
	};

    sso.utils = utils;

})(window, document, window.sso, jQuery);